import { useEffect } from "react";
import Dashboard from "./Dashboard/Dashboard";
import { connectWithSocketServer } from "./SocketConnection/SocketConn";

function App() {
  // Connect with socket server
  useEffect(() => {
    connectWithSocketServer();
  }, []);


  return (
    <div className="App">
        <Dashboard />
    </div>
  );
}

export default App;
