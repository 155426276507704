import {io} from 'socket.io-client';
import {store} from '../store';
import {setConversations, setConversationHistory} from '../Dashboard/dashboardSlice';

let socket;

// Connect with socket server from the App.js file
export const connectWithSocketServer = () => {
    // socket = io('http://localhost:5000');
    socket = io('https://6ycayuy4y0ip.share.zrok.io');

    
    socket.on('connect', () => {
        console.log(`Connected with socket.io server with ID: ${socket.id}`);

        // Get session history
        socket.emit('session-history', {
            sessionId: localStorage.getItem('sessionId'),
        });

        socket.on('session-details', (data) => {
            const {sessionId, conversations} = data;

            localStorage.setItem('sessionId', sessionId);
            store.dispatch(setConversations(conversations));
        })

        //conversation-details
        socket.on('conversation-details', (conversation) => {
            store.dispatch(setConversationHistory(conversation));
        })
    });
};

// Send message to the server from the NewMessageInput.js file
export const sendConversationMessage = (message, conversationId) => {
    socket.emit('conversation-message', 
        {
            sessionId: localStorage.getItem('sessionId'), 
            message, 
            conversationId,
        }
    );
};

export const deleteConversationsFromServerSide = () => {
    socket.emit('conversations-delete', {
        sessionId: localStorage.getItem('sessionId'),
    });
}