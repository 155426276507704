import React from 'react';
import NewChatButton from './NewChatButton';
import ListItem from './ListItem';
import DeleteConversationsButton from './DeleteConversationsButton';

import { useDispatch, useSelector } from 'react-redux';
import { setSelectedConversationId } from '../dashboardSlice';


const Sidebar = () => {

  const dispatch = useDispatch();

  const conversations = useSelector((state) => state.dashboard.conversations);

  const handleSetSelectedChat = (id) => {
    dispatch(setSelectedConversationId(id));

  }

  return (
    <div className='sidebar_container'>
      <NewChatButton handleSetSelectedChat={handleSetSelectedChat}/>

      {
        conversations.map(
          (convo) => (<ListItem
              key={convo.id}
              title={convo.messages[0].content}
              conversationId={convo.id}
              handleSetSelectedChat={handleSetSelectedChat}
          />)
        )
      }
   
      <DeleteConversationsButton/>
    </div>
  );
}

export default Sidebar;