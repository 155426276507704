import React from 'react';
import ChatLogo from './Logo';
import Messages from './Messages';
import {useSelector} from 'react-redux'; 
import NewMessageInput from './NewMessageInput';

const Chat = () => {
  const selectedConversationId = useSelector(
    (state) => state.dashboard.selectedConversationId
  );


  return (
    <div className='chat_container'>

      {!selectedConversationId ? (
        <ChatLogo/>
      ):(
        <div className='chat_selected_container'>
          <Messages/>

          <NewMessageInput/>
        </div>
      )}

        
    </div>
  );
};

export default Chat;