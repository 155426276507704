import React from 'react';
// import { AiOutlineDelete } from 'react-icons/ai';
import { FcFullTrash } from "react-icons/fc";

import { useDispatch } from 'react-redux';
import { deleteConversationsFromStore } from '../dashboardSlice';
import { deleteConversationsFromServerSide } from '../../SocketConnection/SocketConn';



const DeleteConversationsButton = () => {
  const dispatch = useDispatch();
  const handleDeleteConversations = () => {
    dispatch(deleteConversationsFromStore([]));
    deleteConversationsFromServerSide();
  };

  return (
    <div className='list_item delete_conv_button' onClick={handleDeleteConversations}>
      <div className='list_item_icon'>
        <FcFullTrash color='white'/>
      </div>
      <p className='list_item_text'>Delete Conversations</p>
    </div>
  );
}

export default DeleteConversationsButton;