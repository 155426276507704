import { createSlice } from "@reduxjs/toolkit";

// Define the initial state for the dashboard slice of the Redux store
const initialState = {
    sessionEstablished: false, // Flag indicating if a session is established
    conversations:[], // Array to hold conversations
    selectedConversationId: null, // ID of the selected conversation
};

// Create a slice of the Redux store called 'dashboard'
const dashboardSlice = createSlice({
    name: "dashboard", // Name of the slice
    initialState: initialState, // Initial state defined above
    reducers: {
        // Reducer function to set the selected conversation ID
        setSelectedConversationId: (state, action) => {
            state.selectedConversationId = action.payload; // Update selectedConversationId with the payload
        },

        // Reducer function to add a message to a conversation
        addMessage: (state, action) => {
            // Extract message and conversation ID from the payload
            const { message, conversationId } = action.payload;

            // Find the conversation with the provided ID
            const conversation = state.conversations.find(
                (convo) => convo.id === conversationId
            );

            // If the conversation exists, push the message to its messages array
            if (conversation) {
                conversation.messages.push(message);
            } else { // If the conversation doesn't exist, create a new conversation object and add the message to it
                state.conversations.push({
                    id: conversationId,
                    messages: [message],
                });
            }
        },

        setConversations: (state, action) => {
            state.conversations = action.payload;
            state.sessionEstablished = true;
        },

        deleteConversationsFromStore: (state) => {
            state.conversations = [];
            state.selectedConversationId = null;
            state.sessionEstablished = true;
        },

        setConversationHistory: (state, action) => {
            const {id, messages} = action.payload;

            const conversation = state.conversations.find((convo) => convo.id === id);

            if (conversation) {
                conversation.messages = messages;
            } else {
                state.conversations.push({id, messages});
            }

        },
    },
});

// Export individual action creators from the slice
export const {setSelectedConversationId, addMessage, setConversations, setConversationHistory, deleteConversationsFromStore} = dashboardSlice.actions;

// Export the reducer function generated by createSlice
export default dashboardSlice.reducer;
