import React from 'react';
import { useSelector } from 'react-redux';
//Sub components
import Sidebar from './Sidebar/Sidebar';
import Chat from './Chat/Chat';
import LoadingSpinner from './LoadingSpinner';
//Styles
import './dashboard.css';

const Dashboard = () => {
  const sessionEstablished = useSelector(
    (state) => state.dashboard.sessionEstablished
  )

  return (
    <div className='dashboard_container'>
      <Sidebar />
      <Chat />


      {!sessionEstablished && <LoadingSpinner />}
    </div>
  );
}

export default Dashboard;
  