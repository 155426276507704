import React, {useState} from 'react';
import { BsSend } from 'react-icons/bs';
import { v4 as uuid } from 'uuid';
import { useSelector, useDispatch } from 'react-redux';

import { addMessage, setSelectedConversationId } from '../dashboardSlice';
import { sendConversationMessage } from '../../SocketConnection/SocketConn';


const NewMessageInput = () => {
    const [content, setContent] = useState("");

    const selectedConversationId = useSelector(
        (state) => state.dashboard.selectedConversationId
    );

    const dispatch = useDispatch();

    const proceedMessage = () => {
        //Message object to be sent to the server
        const message = {
            aiMesssage: false,
            content: content,
            id : uuid(),
            animate: false,

        }
        console.log(message);


        const conversationId =  selectedConversationId === 'new' ? uuid() : selectedConversationId;
        //Append this message to our local store:
        dispatch(
            addMessage({
                message, conversationId,
            })
        );
        dispatch(setSelectedConversationId(conversationId));


        //Send this message to the server
        sendConversationMessage(message, conversationId);


        //Reset value of the input textfield
        setContent("");

    };

    const handleSendMessage = () => {
        if (content.length > 0){
            proceedMessage();
        }
    };

    const handleKeyPressed = (event) => {
        if (event.key === 'Enter' && content.length > 0){
            proceedMessage();
        }
    };


    return (
        <div className='new_message_input_container'>
            <input 
                className='new_message_input' 
                placeholder='Send a message ...'
                value={content}
                onChange={(event) => setContent(event.target.value)} 
                onKeyDown={handleKeyPressed}
            />
            <div className='new_message_icon_container' onClick={handleSendMessage}> 
                <BsSend color='grey'/>
            </div>

        </div>
    );
};

export default NewMessageInput;