import React from 'react';
import Message from './Message';
import { useSelector } from 'react-redux';

const Messages = () => {

  const {selectedConversationId, conversations} = useSelector(
    (state) => state.dashboard
  );

  const conversation = conversations.find(
    (convo) => convo.id === selectedConversationId
  );

  return (
    <div className='chat_messages_container'>
      {
        conversation?.messages.map((message, index) => 
          (<Message 
            key={message.id}
            content={message.content}
            aiMessage={message.aiMessage}
            animate={index === conversation.messages.length - 1 && message.aiMessage}
          />)
        )
      }
    </div>
  );
};

export default Messages;