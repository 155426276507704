import React from 'react';
// import { AiOutlinePlus } from 'react-icons/ai';
import { FcPlus } from "react-icons/fc";

const NewChatButton = ({handleSetSelectedChat}) => {

  const handleChooseNewChat = () => {
    handleSetSelectedChat("new");
  }

  return (
    <div className='new_chat_button' onClick={handleChooseNewChat}>
      <div className='new_chat_button_icon'>
        <FcPlus color='white'/>
      </div>
      <p className='new_chat_button_text'> New Chat </p>
    </div>
  );
}

export default NewChatButton;